






















































































































































































import { Vue, Component } from 'vue-property-decorator';
import ApiFileForm from './api-file-form.vue';
@Component({
  components: { ApiFileForm }
})
export default class Todo extends Vue {
  activeKey = 1;
  formData: any = {
    c10: '每日'
  };
  rules: any = {
    c1: [{ required: true, message: '文件接口名称不能为空', whitespace: true, trigger: 'blur' }],
    c2: [{ required: true, message: '文件接口编码不能为空', whitespace: true, trigger: 'blur' }]
  };

  modelFieldList: any = [];

  addList() {
    this.modelFieldList.push({});
  }

  removeList(index: number) {
    this.modelFieldList.splice(index, 1);
  }

  calcList: any[] = [];
  addCalc() {
    this.calcList.push({});
  }
  removeCalc(index: number) {
    this.calcList.splice(index, 1);
  }
}
