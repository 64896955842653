




















































import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Todo extends Vue {
  formData: any = {
    c10: '每日'
  };
  rules: any = {
    c1: [{ required: true, message: '文件接口名称不能为空', whitespace: true, trigger: 'blur' }],
    c2: [{ required: true, message: '文件接口编码不能为空', whitespace: true, trigger: 'blur' }]
  };
}
